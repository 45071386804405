@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@400;700&family=Montserrat:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
h1 {
  font-family: Montserrat;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h2 {
  font-family: Montserrat;
  font-size: 36px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h3 {
  font-family: Montserrat;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* h4 font family mention */
h4 {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
/* h5 font family mention */
h5 {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
h6 {
  font-family: Manrope;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

p {
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;
}
.btn1 {
  @apply py-4 px-6 border 
    inline-flex justify-center items-center rounded-[50px] font-Montserrat font-bold text-sm;
}
.btn1:hover {
  @apply bg-troo-primary transition-all;
}
.show_nav {
  position: fixed;
  top: -150px;
  width: 100%;
  transition: top 0.3s;
  z-index: 999;
  background: var(--gradiant, linear-gradient(90deg, #2328b6 0%, #843cc6 100%));
  box-shadow: 0px 20px 25px -5px rgba(0, 0, 0, 0.1),
    0px 10px 10px -5px rgba(0, 0, 0, 0.04);
}
.main_container2 {
  @apply max-w-[1800px] mx-auto px-5 xl:px-7;
}
.main_container1 {
  @apply max-w-[1400px] mx-auto px-5 xl:px-7;
}
.nav_item {
  @apply font-Montserrat font-normal xl:mr-9 mr-4  relative flex text-center capitalize;
}
.dropdown-content .active {
  @apply bg-gradient-to-r from-troo-gad1 to-troo-gad2 text-troo-white;
}
.nav_link {
  @apply flex items-center h-full text-troo-white text-sm xl:text-base;
}
.nav_link.active {
  @apply text-troo-primary;
}
.gradient {
  position: relative;
}
.gradient::before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--gradiant, linear-gradient(90deg, #2328b6 0%, #843cc6 100%));
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}
.gradientlight {
  position: relative;
}
.gradientlight::before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--gradiant, linear-gradient(90deg, #2328b6 0%, #843cc6 100%));
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.4;
}
.benifitesImg {
  position: relative;
  width: 100%;
}
.benifitesImg::after {
  content: "";
  width: 100%;
  height: 80%;
  background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
  border-radius: 0px 0px 5px 5px;
  position: absolute;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease 0s;
  transform: rotate(-180deg);
}


.benifiteIcon {
  background: #f79522;
  width: 100px;
  height: 100px;
  line-height: 150px;
  border-radius: 50%;
  text-align: center;
  display: block;
  transition: all 0.5s ease-in-out 0s;
  margin: 27px auto -48px;
  z-index: 1;
  position: relative;
}
.benifiteCard {
  position: relative;
}
.benifiteCard:hover .benifiteIcon {
  background: var(--gradiant, linear-gradient(90deg, #2328b6 0%, #843cc6 100%));
}
.benifiteCard::before {
  content: "";
  background: var(--gradiant, linear-gradient(90deg, #2328b6 0%, #843cc6 100%));
  width: 100%;
  height: 1px;
  position: absolute;
  bottom: 0;
  transform: scale(0);
  left: 0;
  transition: all 0.5s ease-in-out 0s;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.benifiteCard:hover {
  box-shadow: 0px 6px 20px rgba(0, 0, 0, 0.05);
}
.benifiteCard:hover:before {
  transform: scale(1);
}
.collapse-plus .collapse-title:after {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--gradiant, linear-gradient(90deg, #2328b6 0%, #843cc6 100%));
  color: #fff;
  border-radius: 5px;
  font-weight: 500;
}
.height-0,
:where(.collapse > input[type="radio"]) {
  min-height: 0;
}
:where(
    .menu
      li:not(.menu-title):not(.disabled)
      > *:not(ul):not(details):not(.menu-title)
  ):not(.active):hover,
:where(
    .menu
      li:not(.menu-title):not(.disabled)
      > details
      > summary:not(.menu-title)
  ):not(.active):hover {
  background-color: #0250ac15;
}
.menu :where(li:not(.menu-title) > *:not(ul):not(details):not(.menu-title)),
.menu :where(li:not(.menu-title) > details > summary:not(.menu-title)) {
  border-radius: inherit;
  padding: 15px 15px;
}
.collapse-plus .collapse-title:after{
  right: 0.5rem;
}

@media (max-width:1024px){
  h1 {
    font-size: 50px;
  }
  h2 {
    font-size: 32px;
    line-height: 40px;
  }
  .about{
    background-image: none;
  }

}
@media (max-width:768px){
  h1 {
    font-size: 36px;
  }
  h2 {
    font-size: 28px;
    line-height: 40px;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 16px;   
  }
  h5 {
    font-size: 14px;
    
  }
  h6 {
    font-size: 12px;
  }
  p {
    font-size: 14px;
  }

}